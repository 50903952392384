import {
  MotifButton,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader
} from '@ey-xd/motif-react';
import clsx from 'clsx';
import RoundIconContainer from 'components/pageContents/atoms/roundIconContainer/roundIconContainer.component';
import { CommonIcons } from 'images/commonIcons';
import React from 'react';
import { LineagePopupData } from 'shared/models/Sankey';

import styles from './thematicModalWindow.module.scss';

interface ModalProps {
  isModalOpen: boolean;
  handleModalClose: any;
  modalData: LineagePopupData;
}

const ThematicModalWindow = ({
  isModalOpen,
  handleModalClose,
  modalData
}: ModalProps): JSX.Element => {
  const popupHeading = 'Core Data Attribute Details';
  const closeButtonProps = {
    'aria-label': 'Close',
    title: 'Close'
  };

  const roundCustomStyle = {
    backgroundColor: '#2E2E38'
  };
  return (
    <MotifModal
      onClose={handleModalClose}
      variant="alt"
      className={styles.modal}
      show={isModalOpen}>
      <MotifModalHeader closeButtonProps={closeButtonProps} className={styles.modalHeaderBox}>
        <div className={styles.modalHeaderBoxInner}>
          <div>
            <RoundIconContainer customStyle={roundCustomStyle} icon={CommonIcons.modalWindowSvg} />
          </div>
          <span className={clsx(styles.headingText, styles.removePadding)}>{popupHeading}</span>
        </div>
      </MotifModalHeader>
      <MotifModalBody className={styles.removePadding}>
        <React.Fragment key=".0">
          <div className={clsx(styles.contentBorder, 'motif-row')}>
            <div className={clsx('motif-col-xs', styles.removePadding)}>
              <h6 className={styles.modalBodyHeading}>Attribute Description</h6>
              <p className={styles.textValue} style={{ padding: '4px' }}>
                {modalData.value}
              </p>
            </div>
          </div>
          <div className={clsx(styles.contentBorder, 'motif-row')}>
            <div className={clsx('motif-col-xs', styles.removePadding)}>
              <h6 className={styles.modalBodyHeading}>Attribute Name</h6>
              <p className={styles.textValue}>{modalData.attributeName}</p>
            </div>
          </div>
          <div className={clsx(styles.contentBorder, 'motif-row')}>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Reporting Framework</div>
              <p className={styles.textValue}>{modalData.csrdAny}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Expected Data Source</div>
              <p className={styles.textValue}>{modalData.dataType}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Sustainability Pillar</div>
              <p className={styles.textValue}>{modalData.esgPillar}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Reporting Framework Section</div>
              <p className={styles.textValue}>{modalData.esrsE1}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Expected Data Type</div>
              <p className={styles.textValue}>{modalData.expDataType}</p>
            </div>
          </div>
          <div className={clsx(styles.contentBorder, 'motif-row')}>
            <div className={clsx('motif-col-xs', styles.removePadding)}>
              <h6 className={styles.modalBodyHeading}>General_Calculation</h6>
              <p className={styles.textValue}>{modalData.generalCalculation}</p>
            </div>
          </div>
          <div className={clsx(styles.contentBorder, 'motif-row')}>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Measure</div>
              <p className={styles.textValue}>{modalData.measure}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Metric</div>
              <p className={styles.textValue}>{modalData.metric}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Subject</div>
              <p className={styles.textValue}>{modalData.subject}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.fixPadding)}>
              <div className={styles.modalBodyHeading}>Sub_Sub_Domain</div>
              <p className={styles.textValue}>{modalData.subSubDomain}</p>
            </div>
          </div>
          <div className={clsx(styles.contentBorder, 'motif-row')}>
            <div className={clsx('motif-col-xs', styles.removePadding)}>
              <div className={styles.modalBodyHeading}>Unit_of_Measure</div>
              <p className={styles.textValue}>{modalData.unitOfMeasure}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.removePadding)}>
              <div className={styles.modalBodyHeading}>id</div>
              <p className={styles.textValue}>{modalData.id}</p>
            </div>
            <div className={clsx('motif-col-xs', styles.removePadding)}>
              <div className={styles.modalBodyHeading}>Regulation_Mapping</div>
              <p className={styles.textValue}>{modalData.regulationMapping}</p>
            </div>
          </div>
        </React.Fragment>
      </MotifModalBody>
      <MotifModalFooter className={clsx(styles.footer)}>
        <React.Fragment key=".0">
          <MotifButton
            onClick={handleModalClose}
            className={styles.modalCloseButton}
            size="large"
            type="button"
            variant="primary-alt">
            Close
          </MotifButton>
        </React.Fragment>
      </MotifModalFooter>
    </MotifModal>
  );
};
export default ThematicModalWindow;
