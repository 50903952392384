import { MotifCard, MotifProgressLoader } from '@ey-xd/motif-react';
import clsx from 'clsx';
import ErrorModalWindow from 'components/pageContents/molecules/errorModalWindow/errorModalWindow.component';
import { Linking, Node, Sankey } from 'components/pageContents/molecules/lineage/Sankey';
import MetricsCountWidget from 'components/pageContents/molecules/lineage/metricsCountWidget/metricsCountWidget.component';
import ThematicModalWindow from 'components/pageContents/molecules/lineage/thematicModalWindow/thematicModalWindow.component';
import { SankeyExtraProperties, SankeyLink, SankeyNode } from 'd3-sankey';
import { LineagePageIcons } from 'images/lineagePageIcons';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { InitialThematicNodeData as InitialNodeData } from 'shared/data/sankeyColumnDefinition';
import { THEMATIC_LINEAGE_MAX_LEVEL_X } from 'shared/helpers/constants';
import { ThreeMetrics } from 'shared/models/AppModel';
import { ExtraNodeProperties, LineagePopupData, customSakeyModal } from 'shared/models/Sankey';
import { useAppDispatch, useAppStore } from 'shared/redux/hooks';
import {
  fetchThematicLineageData,
  updateThematicMetricCount
} from 'shared/redux/slice/ThematicLineageDataSlice';

import styles from './thematicLineagePage.module.scss';

const ThematicLineagePage = (): JSX.Element => {
  const pageName = 'Thematic Lineage';

  const MetricsTypes = {
    primaryDisclosureMetrics: 'Primary Disclosures',
    secondaryMetrics: 'Intermediate Attributes',
    coreDataAttributes: 'Core Data Attributes'
  };

  const dispatch = useAppDispatch();

  const [maxLevelXSelected, setMaxLevelXSelected] = useState<number>(-1);

  // Graph data used for lineage page
  const [graphData, setSelectedGraphData] = useState<{
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[];
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[];
  }>();

  const [error, setError] = useState<string>('');

  // For Width and Height as per node data
  const [maxLevelX, setMaxLevelX] = useState<number>(0);
  const [maxLevelY, setMaxLevelY] = useState<number>(0);

  // Node Data from API
  const [nodeData, setNodeData] = useState<
    SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[]
  >(InitialNodeData.concat());

  // modal data
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<LineagePopupData | undefined>();

  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoader, setInitialLoader] = useState<boolean>(false);

  const { thematicLineagePageData, metricCount } = useAppStore().thematicLineagePageSlice;

  useEffect(() => {
    if (Object.keys(thematicLineagePageData).length > 0) {
      setLoading(true);
      const mutableObject = JSON.parse(JSON.stringify(thematicLineagePageData[0]));
      setNodeData(mutableObject);
      const zeroLevelNodes = mutableObject.filter((node: any) => {
        return node.levelX == 0;
      });
      setSelectedGraphData({ nodes: zeroLevelNodes, links: [] });
      let levelX = 0;
      let levelY = 0;
      mutableObject.map((node: any) => {
        levelX = node.levelX > levelX ? node.levelX : levelX;
        levelY = node.levelY > levelY ? node.levelY : levelY;
      });
      setMaxLevelY(levelY);
      setLoading(false);
    }
  }, [Object.keys(thematicLineagePageData).length]);

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(thematicLineagePageData).length == 0) {
        setLoading(true);
        setInitialLoader(true);
        const responseData = await dispatch(
          fetchThematicLineageData({ columnId: 0, nodeId: 0, parentKey: '0', selectedNodes: [] })
        );
        if (responseData?.payload?.data) {
          const payloadData: Record<string, any> = responseData?.payload.data;
          const zeroLevelNodes = _.cloneDeep(payloadData[0]);
          setNodeData(zeroLevelNodes);
          setSelectedGraphData({ nodes: zeroLevelNodes, links: [] });
          const maxLevelValues = calculateMaxLevel(zeroLevelNodes);
          setMaxLevelX(maxLevelValues.levelX);
          setMaxLevelY(maxLevelValues.levelY);
        }
        setLoading(false);
        setInitialLoader(false);
      }
      // setMetricCount({ primary: 0, coreData: 0, secondary: 0 } as ThreeMetrics);
    };
    fetchData();
  }, []);

  const calculateMaxLevel = (nodes: any) =>
    nodes.reduce(
      (final: any, current: any) => {
        return {
          levelX: final.levelX < current.levelX ? current.levelX : final.levelX,
          levelY: final.levelY < current.levelY ? current.levelY : final.levelY
        };
      },
      {
        levelX: 0,
        levelY: 0
      }
    );

  const setMetricCount = ({ primary, coreData, secondary }: ThreeMetrics): void => {
    dispatch(
      updateThematicMetricCount({
        primary: Number(primary || 0),
        coreData: Number(coreData || 0),
        secondary: Number(secondary || 0)
      })
    );
  };

  const onNodeClick = async (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[],
    currentNode: any
  ) => {
    // check if loading is true then return
    if (loading) return;

    // check if the node Id is empty then return
    if (currentNode.key === '') {
      return;
    }

    // find all the nodes id above the current node
    // const allDeleteNodes = nodeData
    //   .filter((data) => data.levelX > currentNode.levelX)
    //   .reduce((acc: any, cur) => {
    //     const parentID = cur.parentKey;
    //     if (parentID && !acc.includes(parentID)) {
    //       acc.push(parentID);
    //     }
    //     return acc;
    //   }, []);
    const currentNodeId = currentNode.key || 'node-0';
    // dispatch(deleteThematicNodeData(allDeleteNodes));
    setMaxLevelXSelected(currentNode.levelX);
    // if (currentNode?.extraData?.count) {
    //   setMetricCount(currentNode?.extraData?.count as ThreeMetrics);
    // }

    let inComingNodes: customSakeyModal[] = [];
    setLoading(true);

    if (currentNode.levelX == THEMATIC_LINEAGE_MAX_LEVEL_X) {
      inComingNodes = [];
      const extraData = await dispatch(
        fetchThematicLineageData({
          columnId: currentNode.levelX + 1,
          nodeId: currentNode.key,
          parentKey: currentNode.key,
          selectedNodes: nodes.filter((value) => {
            return value.isSelected;
          })
        })
      );
      setLoading(false);
      const modalData = {
        ...{
          attributeName: '',
          csrdAny: '',
          esgPillar: '',
          esrsE1: '',
          generalCalculation: '',
          subSubDomain: '',
          unitOfMeasure: '',
          id: ''
        },
        ...extraData.payload
      };
      const selectedNode = nodes.filter((value) => {
        return value.isSelected;
      });
      selectedNode.map((node: any) => {
        switch (node.levelX) {
          case 0:
            //Can pick from path RegulationName's value
            modalData.csrdAny = node.name;
            break;
          case 1:
            //Can pick from path; Pillar's value
            modalData.esgPillar = node.name;
            break;
          case 2:
            //Can pick from path; Topic's value
            modalData.esrsE1 = node.name;
            break;
          case 4:
            //Can pick from path; Pillar's value
            modalData.generalCalculation = node.name;
            break;
          case 5:
            //Can pick from path; Pillar's value
            modalData.attributeName = node.name;
            break;
          default:
            console.log('default');
        }
      });
      setSelectedGraphData({
        nodes: nodes,
        links: links
      });
      setModalData(modalData);
      setModalOpen(true);
    } else {
      inComingNodes = [];
      const responseData = await dispatch(
        fetchThematicLineageData({
          columnId: currentNode.levelX + 1,
          nodeId: currentNode.key,
          parentKey: currentNode.key,
          selectedNodes: nodes.filter((value) => {
            return value.isSelected;
          })
        })
      );
      if (responseData?.payload?.data) {
        const payloadData: Record<string, any> = responseData?.payload?.data;
        inComingNodes = _.cloneDeep(payloadData[currentNodeId]);
      }
      if (inComingNodes.length > 0) {
        const newNodeData = nodeData.filter((data) => data.levelX <= currentNode.levelX);
        const fullNode = [...newNodeData, ...inComingNodes];
        const maxLevelValues = calculateMaxLevel(fullNode);
        setMaxLevelX(maxLevelValues.levelX);
        setMaxLevelY(maxLevelValues.levelY);
        await setNodeData(fullNode);
        const newNodeToDraw = [...nodes, ...inComingNodes];
        setSelectedGraphData({
          nodes: newNodeToDraw,
          links: links
        });
      } else {
        setSelectedGraphData({
          nodes: nodes,
          links: links
        });
      }
      setLoading(false);
    }
  };

  const handleModalOpen = (
    nodeObject: any,
    nodePathSelected: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[]
  ) => {
    const modalData = {
      ...nodeObject?.extraData?.modalData,
      ...{
        attributeName: nodeObject.name
      }
    };
    nodePathSelected.map((node: any) => {
      switch (node.levelX) {
        case 0:
          //Can pick from path RegulationName's value
          modalData.csrdAny = node.name;
          break;
        case 1:
          //Can pick from path; Pillar's value
          modalData.esgPillar = node.name;
          break;
        case 2:
          //Can pick from path; Topic's value
          modalData.esrsE1 = node.name;
          break;
        case 4:
          //Can pick from path; Pillar's value
          modalData.generalCalculation = node.name;
          break;
        case 5:
          //Can pick from path; Pillar's value
          modalData.attributeName = node.name;
          break;
        default:
          console.log('default');
      }
    });
    setModalData(modalData);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setError('');
  };

  const updateSearchResult = (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[]
  ) => {
    setSelectedGraphData({
      nodes: nodes,
      links: links
    });
  };

  const updateNodeExpansion = (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[]
  ) => {
    setSelectedGraphData({
      nodes: nodes,
      links: links
    });
  };

  const updateFilteredRegulation = (
    nodes: SankeyNode<ExtraNodeProperties, SankeyExtraProperties>[],
    links: SankeyLink<ExtraNodeProperties, SankeyExtraProperties>[],
    selectedVal: string[]
  ) => {
    // update the top level widgets.
    if (selectedVal && selectedVal.length > 0) {
      const selectedSubTopic: any = links.filter(
        (item: any) => item.index == 2 && item.target?.levelX == 3
      );
      const regulationCounts = selectedSubTopic[0]?.target?.extraData?.regulationCount || [];
      const finalValue: ThreeMetrics = regulationCounts
        .filter((item: { count: ThreeMetrics; name: string }) => selectedVal.includes(item.name))
        .reduce(
          (accumulator: any, currentValue: any) => {
            return {
              primary: accumulator.primary + currentValue.count?.primary,
              secondary: accumulator.secondary + currentValue.count?.secondary,
              coreData: accumulator.coreData + currentValue.count?.coreData
            };
          },
          {
            primary: 0,
            secondary: 0,
            coreData: 0
          }
        );
      setMetricCount(finalValue);
    } else {
      setMetricCount({ primary: 0, coreData: 0, secondary: 0 } as ThreeMetrics);
    }
    setSelectedGraphData({
      nodes: nodes,
      links: links
    });
  };

  return (
    <>
      <div className={clsx('motif-container', styles.borderBottom)}>
        <div className={clsx('widgetSpacing', 'motif-row')}>
          <div className="pageHeading">
            <h2>{pageName}</h2>
          </div>
        </div>

        <div className={clsx('widgetSpacing', 'motif-row')} style={{ paddingBottom: '15px' }}>
          <div
            className={clsx('motif-col-lg-4', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
              <MetricsCountWidget
                count={metricCount.primary}
                metricsName={MetricsTypes.primaryDisclosureMetrics}
                icon={LineagePageIcons.onePointCircleSvg}
              />
            </MotifCard>
          </div>
          <div
            className={clsx('motif-col-lg-4', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
              <MetricsCountWidget
                count={metricCount.secondary}
                metricsName={MetricsTypes.secondaryMetrics}
                icon={LineagePageIcons.twoPointsCircleSvg}
              />
            </MotifCard>
          </div>
          <div
            className={clsx('motif-col-lg-4', 'motif-col-md', 'motif-col-xs-4', styles.widgetBox)}>
            <MotifCard className={clsx(styles.smallWidgets, 'customMotifCard')}>
              <MetricsCountWidget
                count={metricCount.coreData}
                metricsName={MetricsTypes.coreDataAttributes}
                icon={LineagePageIcons.xrayViewSvg}
              />
            </MotifCard>
          </div>
        </div>
      </div>
      <div className="motif-container">
        <div className={clsx('widgetSpacing', 'motif-row')} style={{ overflowX: 'auto' }}>
          {graphData && (
            <Sankey<ExtraNodeProperties, SankeyExtraProperties>
              data={graphData}
              nodeWidth={150}
              height={110 + maxLevelY * 80}
              width={200 * (maxLevelX + 1) + 100 * maxLevelX}
              nodePadding={40}>
              {({ graph }) => {
                return (
                  <g>
                    {graph.links &&
                      graph.links.map((link: any, i) => (
                        <Linking
                          key={`sankey-link-${i}`}
                          link={link}
                          color={'#DA5BB3'}
                          maxWidth={30}
                          graph={graph}
                        />
                      ))}
                    {graph &&
                      graph.nodes.map((node, i) => {
                        return (
                          <Node<ExtraNodeProperties, SankeyExtraProperties>
                            key={`sankey-node-${i}`}
                            index={i}
                            currentNode={node}
                            height={60}
                            width={200}
                            graph={graph}
                            onNodeClicked={onNodeClick}
                            maxLevelXSelected={maxLevelXSelected}
                            apiNodeData={nodeData}
                            maxLevelX={maxLevelX}
                            openModal={handleModalOpen}
                            updateSearchOnView={updateSearchResult}
                            updateNodeExpansionOnView={updateNodeExpansion}
                            updateFilteredRegulation={updateFilteredRegulation}
                          />
                        );
                      })}
                  </g>
                );
              }}
            </Sankey>
          )}
        </div>
      </div>

      {modalData && (
        <ThematicModalWindow
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          modalData={modalData}
        />
      )}

      {error && (
        <ErrorModalWindow isModalOpen={error.length > 0} handleModalClose={handleErrorModalClose} />
      )}

      <MotifProgressLoader show={initialLoader} className={clsx(styles.plainLoader)} />
    </>
  );
};
export default ThematicLineagePage;
